import { useContext } from "react";
import { Collapse, Snackbar, Alert } from "@mui/material";
import FeedbackContext from "../feedbackContext";

export const SEVERITY = {
  ERROR: "error",
  INFO: "info",
  SUCCESS: "success",
  WARNING: "warning",
};

export const AUTO_HIDE_DURATION_ERROR = 60000;
export const AUTO_HIDE_DURATION = 5000;

export const FeedbackComponent = () => {
  const { feedback, setFeedback } = useContext(FeedbackContext);

  const handleCloseFeedback = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ isOpen: false });
  };
  if (!feedback?.isOpen) {
    return null;
  }

  let autoHideDuration;

  if (feedback?.autoHideDuration !== undefined) {
    autoHideDuration = feedback.autoHideDuration;
  } else if (feedback?.severity === SEVERITY.ERROR) {
    autoHideDuration = AUTO_HIDE_DURATION_ERROR;
  } else {
    autoHideDuration = AUTO_HIDE_DURATION;
  }

  return (
    <Snackbar
      open={feedback?.isOpen}
      autoHideDuration={autoHideDuration}
      onClose={handleCloseFeedback}
      data-testid='feedback-snackbar'
    >
      <Collapse in={feedback?.isOpen}>
        {feedback?.messages.map((message, index) => (
          <Alert
            key={message + index}
            severity={feedback?.severity || SEVERITY.ERROR}
            onClose={handleCloseFeedback}
          >
            {message}
          </Alert>
        ))}
      </Collapse>
    </Snackbar>
  );
};
