import { useContext, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import { useReportTypes } from "../hooks/useReportTypes";
import { generateReport } from "../dao/report";
import FeedbackContext from "../feedbackContext";
import { AUTO_HIDE_DURATION, SEVERITY } from "./FeedbackComponent";
import { exportToFile } from "../utils/utils";

export const ReportDropdown = ({ selectedRows = [], entity }) => {
  const { reportTypes, error: errorLoadingReportTypes } =
    useReportTypes(entity);
  const { setFeedback } = useContext(FeedbackContext);

  const [isLoadingReportData, setIsLoadingReportData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  /**
   * Event listeners
   */
  const handleMenuOpen = async (event) => {
    // open the menu
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (type) => {
    const requestPayload = {
      ids: selectedRows.map((row) => row.id),
      entity,
      report_name: type.display_name,
    };
    setIsLoadingReportData(true);

    try {
      const reportsData = await generateReport(requestPayload);

      // if there are reports available
      if (reportsData.length > 0) {
        // iterate through the reportsData
        reportsData.forEach((report, index) => {
          // decode the base64 string and convert to Uint8Array
          const decodedReport = new Uint8Array(
            Buffer.from(report, "base64")
              .toString("binary")
              .split("")
              .map((char) => char.charCodeAt(0))
          );

          // export each report as a pdf
          exportToFile(
            `${type.display_name}_${index + 1}.pdf`,
            decodedReport,
            "application/pdf"
          );
        });
        setFeedback({
          isOpen: true,
          messages: ["The report(s) are being downloaded."],
          severity: SEVERITY.SUCCESS,
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      } else {
        // else there are no reports for download
        setFeedback({
          isOpen: true,
          messages: ["There is a report available for download."],
          severity: SEVERITY.INFO,
          autoHideDuration: AUTO_HIDE_DURATION,
        });
      }
    } catch (error) {
      setFeedback({
        isOpen: true,
        messages: [
          error.message ?? "An error occurred while retrieving the report.",
        ],
        severity: SEVERITY.ERROR,
      });
    } finally {
      handleMenuClose();
      setIsLoadingReportData(false);
    }
  };

  if (errorLoadingReportTypes) {
    return (
      <Box sx={{ alignItems: "center", display: "flex", px: 1 }}>
        <IconButton
          disabled={true}
          title='Error loading report types'
          data-testid='report-button'
        >
          <AssignmentTurnedInOutlinedIcon />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box sx={{ alignItems: "center", display: "flex", px: 1 }}>
      <IconButton
        id='report-button'
        aria-controls={open ? "report-menu" : null}
        aria-haspopup='true'
        aria-expanded={open ? "true" : null}
        onClick={handleMenuOpen}
        disabled={selectedRows.length === 0 || reportTypes.length === 0}
        sx={{ padding: 0 }}
        title={selectedRows.length === 0 ? "First select row(s)" : "Report"}
        type='button'
        data-testid='report-button'
      >
        {isLoadingReportData ? (
          <CircularProgress size={18} />
        ) : (
          <AssignmentTurnedInOutlinedIcon size={18} />
        )}
      </IconButton>

      <Menu
        id='report-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "report-button",
        }}
      >
        {reportTypes.map((reportType) => (
          <MenuItem
            key={reportType.id}
            onClick={() => handleMenuItemClick(reportType)}
          >
            {reportType.display_name}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
