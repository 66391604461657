import { Button, Paper, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import { formatPageTitle } from "../utils/pageTitle";

export const Unauthorised = () => {
  const history = useHistory();
  const pageTitle = formatPageTitle("Unauthorized");
  return (
    <Paper
      sx={{
        padding: 4,
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Typography
        variant='h5'
        sx={{
          "& + *": {
            marginTop: 3,
          },
        }}
      >
        Missing permissions
      </Typography>
      <p>
        You do not have sufficient permission to view this page. Don't worry
        though, there is always a way to go back to the dashboard.
      </p>
      <Button
        variant='contained'
        onClick={() => {
          history.push("/");
        }}
      >
        To Dashboard
      </Button>
    </Paper>
  );
};
