import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { Helmet } from "react-helmet";

import CreateItemButton from "../components/CreateItemButton";
import { addSearchParams } from "../utils/dao";
import EditEntity from "../components/EditEntity";
import EntityOverview from "./EntityOverview";
import { AssignModal } from "../components/AssignModal";

import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import EditModes from "../enums/EditModes";
import UserRights from "../enums/UserRights";

import { FILTER_TYPES } from "../dao/types";
import { create, softDelete, update } from "../dao/operations";

import useOrder from "../hooks/useOrder";
import useFields from "../hooks/useFields";
import { useSearchParams } from "../hooks/useSearchParams";
import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import { formatPageTitle } from "../utils/pageTitle";

const API_ROUTE = API_ROUTES["orders"];

const OrderDetail = ({ title = "", mode = "" }) => {
  const history = useHistory();
  const [assignCableModel, setAssignCableModel] = useState({ open: false });
  const { orderId } = useParams();
  const { objectId } = useSearchParams();

  const orderParams = {
    objectId,
    orderId,
  };

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.order);
  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, FILTER_TYPES.order);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.order
  );
  const isAllowedToAssign = isAllowedTo(UserRights.ASSIGN_CABLE);

  const {
    order,
    fetchOrderData,
    isLoading: recordIsLoading,
  } = useOrder(orderParams);

  const [fields] = useFields(
    FILTER_TYPES.order,
    false,
    isCreateMode,
    isUpdateMode
  );

  const pageTitle = formatPageTitle(title);

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: order.object_id }).filter(([, value]) => value)
    ).toString();
  }, [order.object_id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeOrder(payload) {
    if (isUpdateMode) {
      // Existing order
      await update({ ...payload, id: order.id }, { route: API_ROUTE }).then(
        () => {
          fetchOrderData(orderParams);
        }
      );
    } else {
      // Brand new order
      return create(payload, { route: API_ROUTE }).then((res) => {
        history.push(["/order", res.id].join("/"));
      });
    }
  }

  async function removeOrder(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchOrderData(orderParams);
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchOrderData(orderParams);
    } else {
      history.goBack();
    }
  }

  async function restoreDeletedOrder(payload) {
    return softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchOrderData(orderParams);
    });
  }

  const handleAssignment = (state) => {
    setAssignCableModel({ ...state, open: state.open });
  };

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Paper
        sx={{
          padding: 4,
        }}
      >
        <Box
          sx={{
            marginBottom: "1rem",
            justifyContent: "space-between",
            display: "flex",
          }}
        >
          <Typography variant='h5'>{title}</Typography>
          {isUpdateMode && (
            <CreateItemButton
              size='medium'
              entity={FILTER_TYPES.order}
              to={addSearchParams(ROUTES.order.create)(searchParams)}
              isDisabled={!isAllowedToCreate}
            />
          )}
        </Box>
        {!isLoading && (
          <EditEntity
            {...{
              isAllowedToUpdate,
              isAllowedToDelete,
              isAllowedToCreate,
              entity: order,
              fields,
              objectId,
              storeEntity: storeOrder,
              deleteEntity: removeOrder,
              entityType: FILTER_TYPES.order,
              cancel: cancelChanges,
              restoreEntity: restoreDeletedOrder,
              isUpdateMode,
            }}
          />
        )}
        {isUpdateMode && (
          <EntityOverview
            entity={FILTER_TYPES.cable}
            title={"Cables overview"}
            nestedEntity={true}
            parent={{
              object_id: order.object_id,
              order_id: orderId,
              hasAssignButtons: isAllowedToAssign,
              options: {
                handleAssignment,
                assignCableModel,
              },
              entity: { ...order, entityName: FILTER_TYPES.order },
            }}
          />
        )}
        {isAllowedToAssign && (
          <AssignModal
            open={assignCableModel.open}
            onClose={() => handleAssignment({ open: false })}
            modalOptions={{
              entity: FILTER_TYPES.cable,
              title: "Assign cables to the order",
              parent: {
                entity: { ...order, entityName: FILTER_TYPES.order },
                object_id: order.object_id,
                project_id: order.project_id,
                hasAssignButtons: true,
                options: {
                  handleAssignment,
                },
              },
            }}
          />
        )}
      </Paper>
    </>
  );
};

export default OrderDetail;
