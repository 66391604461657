/**
 * Format page title with the application name
 * @param {string} pageTitle - The specific page title
 * @returns {string} - Formatted title
 */
export const formatPageTitle = (pageTitle) => {
  // Return just "OASIS" for empty string, null, undefined, or object inputs
  if (typeof pageTitle === "string" && pageTitle.length > 0) {
    return `OASIS | ${pageTitle}`;
  }
  return "OASIS";
};
