import React from 'react';
import { Box } from '@mui/system';
import InfoIcon from '../components/icons/InfoIcon';

const EnvironmentIndicator = ({ stage }) => {
  if (typeof stage === 'undefined') {
    return null;
  }
  return (<Box
    component="div"
    data-testid="environment-indicator"
    sx={ {
      'background': '#FA8F23',
      'padding': '10px',
      'fontWeight': 'bold',
      'borderRadius': '4px',
      'display': 'flex',
      'alignItems': 'center',
      'position': 'absolute',
      'top': '14px',
      'left': '180px',
      '& svg': {
        fill: '#fff',
        marginRight: '4px',
      },
    } }
  >
    <InfoIcon/>
    { `${ stage } environment` }
  </Box>
  );
};

export default EnvironmentIndicator;
