/**
 * @module dao/report
 * @description Functions for interacting with the report API endpoints
 */
import { get, post } from "../api/api";

/**
 * Fetches report types for a specific entity
 *
 * @async
 * @param {string} entity - The entity type to get report types for
 * @param {AbortController} [abortController] - Optional AbortController to cancel the request
 * @returns {Promise<Array>} A promise that resolves to an array of report types
 */
export async function fetchReportTypes(entity, abortController) {
  const body = await get(
    `/reports/${entity}/types?limit=50&withDeleted=false`,
    abortController
  );
  return body?.response;
}

/**
 * Generates a report with the provided parameters
 *
 * @async
 * @param {Object} payload - The configuration parameters for the report generation
 * @returns {Promise<Object>} A promise that resolves to the generated report data
 */
export async function generateReport(payload) {
  const body = await post("/reports/generate", payload);
  return body?.response;
}
