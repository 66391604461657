import { createContext, useMemo, useState } from "react";
import { AUTO_HIDE_DURATION, SEVERITY } from "./components/FeedbackComponent";

export const FeedbackContext = createContext();

export function FeedbackProvider({ children }) {
  const [feedback, setFeedback] = useState({
    isOpen: false,
    messages: [],
    severity: SEVERITY.INFO,
    autohideDuration: AUTO_HIDE_DURATION,
  });

  const feedbackData = useMemo(
    () => ({
      feedback,
      setFeedback,
    }),
    [feedback, setFeedback]
  );
  return (
    <FeedbackContext.Provider value={feedbackData}>
      {children}
    </FeedbackContext.Provider>
  );
}

export default FeedbackContext;
