import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";

import useFields, { FILTER_TYPES } from "../hooks/useFields";

import useRoutePoint from "../hooks/useRoutePoint";

import EditEntity from "../components/EditEntity";
import CreateItemButton from "../components/CreateItemButton";

import { patchRoutePoint, addRoutePoint } from "../dao/routePoints";
import { softDelete } from "../dao/operations";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";

import EntityOverview from "../views/EntityOverview";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";
import { formatPageTitle } from "../utils/pageTitle";

const API_ROUTE = API_ROUTES["route-points"];

export default function RoutePointDetail({ title = "", mode = "" }) {
  const history = useHistory();

  // update record only
  // get equipmentId from the search params
  const { routePointId } = useParams();

  // create record only
  // get objectId from the search params
  const { objectId } = useSearchParams();

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToCreate = isAllowedTo(
    UserRights.CREATE,
    FILTER_TYPES.route_point
  );

  const isAllowedToUpdate = isAllowedTo(
    UserRights.UPDATE,
    FILTER_TYPES.route_point
  );

  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.route_point
  );

  const {
    routePoint,
    fetchRoutePointData,
    isLoading: recordIsLoading,
  } = useRoutePoint(objectId, routePointId);

  const [fields] = useFields(
    FILTER_TYPES.route_point,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({ objectId: routePoint.object_id }).filter(
        ([, value]) => value
      )
    ).toString();
  }, [routePoint.object_id]);

  const pageTitle = formatPageTitle(title);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function storeRoutePoint(payload) {
    if (isUpdateMode) {
      // Existing routePoint
      log.trace("about to patch", routePoint.id);
      await patchRoutePoint(payload, routePoint.id).then(() => {
        fetchRoutePointData();
      });
    } else {
      // Brand new routePoint
      log.trace("about to create", JSON.stringify(routePoint));
      return addRoutePoint(payload).then((res) => {
        history.push(["/route-point", res.id].join("/"));
      });
    }
  }

  async function removeRoutePoint(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchRoutePointData();
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchRoutePointData();
    } else {
      history.goBack();
    }
  }

  function restoreDeletedRoutePoint(payload) {
    softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchRoutePointData();
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Paper
        sx={{
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography variant='h5'>{title}</Typography>
          {isUpdateMode && (
            <CreateItemButton
              size='medium'
              entity={FILTER_TYPES.route_point}
              to={addSearchParams(ROUTES.routePoints.create)(searchParams)}
              isDisabled={isAllowedToCreate === false}
            />
          )}
        </Box>
        {isLoading === false && (
          <>
            <EditEntity
              {...{
                isAllowedToUpdate,
                isAllowedToDelete,
                isAllowedToCreate,
                entity: routePoint,
                fields,
                objectId,
                storeEntity: storeRoutePoint,
                deleteEntity: removeRoutePoint,
                entityType: FILTER_TYPES.route_point,
                cancel: cancelChanges,
                restoreEntity: restoreDeletedRoutePoint,
                isUpdateMode,
              }}
            />
            {isUpdateMode && (
              <>
                {/* Cable sub overview */}
                <EntityOverview
                  entity={FILTER_TYPES.cable}
                  title={"Cables overview"}
                  nestedEntity={true}
                  parent={{
                    object_id: routePoint.object_id,
                    route_point_id: routePoint.id,
                  }}
                />
              </>
            )}
          </>
        )}
      </Paper>
    </>
  );
}
