import { useEffect, useMemo } from "react";
import { CircularProgress, Paper, Typography } from "@mui/material";
import { useHistory, useParams } from "react-router";
import log from "loglevel";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet";

import useFields, { FILTER_TYPES } from "../hooks/useFields";
import useCable from "../hooks/useCable";

import EditEntity from "../components/EditEntity";

import { addCable, patchCable } from "../dao/cables";

import { softDelete } from "../dao/operations";

import useUserPermissionsContext from "../hooks/useUserPermissionsContext";
import UserRights from "../enums/UserRights";

import CreateItemButton from "../components/CreateItemButton";
import EditModes from "../enums/EditModes";
import { ROUTES } from "../enums/Routes";
import { API_ROUTES } from "../enums/api";
import { useSearchParams } from "../hooks/useSearchParams";
import { addSearchParams } from "../utils/dao";
import { formatPageTitle } from "../utils/pageTitle";

const API_ROUTE = API_ROUTES["cables"];

export default function CableDetail({ title = "", mode = "" }) {
  const history = useHistory();

  // update record only
  // get equipmentId from the search params
  const { cableId } = useParams();

  // create record only
  // get fields from the search params

  const {
    objectId,
    systemGroupId,
    systemId,
    sectionId,
    projectId,
    sourceSectionReference,
    destinationSectionReference,
  } = useSearchParams();

  const cableParams = {
    objectId,
    systemId,
    systemGroupId,
    sectionId,
    cableId,
    projectId,
    sourceSectionReference,
    destinationSectionReference,
  };

  const isUpdateMode = mode === EditModes.UPDATE;
  const isCreateMode = mode === EditModes.CREATE;

  const { isAllowedTo, isLoading } = useUserPermissionsContext();

  const isAllowedToUpdate = isAllowedTo(UserRights.UPDATE, FILTER_TYPES.cable);
  const isAllowedToDelete = isAllowedTo(
    UserRights.SOFT_DELETE,
    FILTER_TYPES.cable
  );
  const isAllowedToCreate = isAllowedTo(UserRights.CREATE, FILTER_TYPES.cable);

  const {
    cable,
    fetchCableData,
    isLoading: recordIsLoading,
  } = useCable(cableParams);

  const [fields] = useFields(
    FILTER_TYPES.cable,
    false,
    isCreateMode,
    isUpdateMode
  );

  const searchParams = useMemo(() => {
    return new URLSearchParams(
      Object.entries({
        objectId: cable.object_id,
        systemGroupId: cable.system_group_id,
        systemId: cable.system_id,
        projectId: cable.project_id,
        sourceSectionId: cable.source_section_id,
        sourceSectionReference: cable.source_section_reference,
        destinationSectionId: cable.destination_section_id,
        destinationSectionReference: cable.destination_section_reference,
      }).filter(([, value]) => value)
    ).toString();
  }, [
    cable.object_id,
    cable.system_group_id,
    cable.system_id,
    cable.project_id,
    cable.source_section_id,
    cable.source_section_reference,
    cable.destination_section_id,
    cable.destination_section_reference,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const pageTitle = formatPageTitle(title);

  async function storeCable(payload) {
    if (isUpdateMode) {
      // Existing cable
      log.trace("about to patch", cable.id);
      await patchCable(payload, cable.id).then(() => {
        fetchCableData(cableParams);
      });
    } else {
      // Brand new cable
      log.trace("about to create", JSON.stringify(cable));
      return addCable(payload).then((res) => {
        history.push(["/cable", res.id].join("/"));
      });
    }
  }

  async function removeCable(payload) {
    if (isUpdateMode) {
      return softDelete({ route: API_ROUTE, delete: true })(payload).then(
        () => {
          fetchCableData(cableParams);
        }
      );
    }
  }

  async function cancelChanges() {
    if (isUpdateMode) {
      fetchCableData(cableParams);
    } else {
      history.goBack();
    }
  }

  function restoreDeletedCable(payload) {
    // return the promise
    return softDelete({ route: API_ROUTE, delete: false })(payload).then(() => {
      fetchCableData(cableParams);
    });
  }

  return !fields || recordIsLoading ? (
    <Box
      sx={{
        marginTop: 3,
        textAlign: "center",
      }}
    >
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <CircularProgress />
    </Box>
  ) : (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Paper
        sx={{
          padding: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "1rem",
          }}
        >
          <Typography variant='h5'>{title}</Typography>
          {isUpdateMode && (
            <CreateItemButton
              size='medium'
              entity={FILTER_TYPES.cable}
              to={addSearchParams(ROUTES.cable.create)(searchParams)}
              isDisabled={isAllowedToCreate === false}
            />
          )}
        </Box>
        {isLoading === false && (
          <EditEntity
            {...{
              isAllowedToUpdate,
              isAllowedToDelete,
              isAllowedToCreate,
              entity: cable,
              fields,
              storeEntity: storeCable,
              deleteEntity: removeCable,
              entityType: FILTER_TYPES.cable,
              cancel: cancelChanges,
              restoreEntity: restoreDeletedCable,
              isUpdateMode,
            }}
          />
        )}
      </Paper>
    </>
  );
}
