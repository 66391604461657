import React, { useContext, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Alert, Divider } from "@mui/material";
import { Box } from "@mui/system";

import Restricted from "../components/Restricted";
import LayoutWrapper from "../components/LayoutWrapper";

import ObjectsContainer from "../components/entities/ObjectsContainer";
import ProjectsContainer from "../components/entities/ProjectsContainer";
import SystemGroupsContainer from "../components/entities/SystemGroupsContainer";
import SystemsContainer from "../components/entities/SystemsContainer";
import LocationsContainer from "../components/entities/LocationsContainer";
import EquipmentsContainer from "../components/entities/EquipmentsContainer";
import SectionsContainer from "../components/entities/SectionsContainer";
import CablesContainer from "../components/entities/CablesContainer";
import CableTypesContainer from "../components/entities/CableTypesContainer";
import RoutePointsContainer from "../components/entities/RoutePointsContainer";
import OrdersContainer from "../components/entities/OrdersContainer";

import UserRights from "../enums/UserRights";
import { FILTER_TYPES } from "../dao/types";
import { CableBundlesContainer } from "../components/entities/CableBundlesContainer";
import DrumsContainer from "../components/entities/DrumsContainer";
import { GlobalContext } from "../globalContext";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../enums/Routes";
import { formatPageTitle } from "../utils/pageTitle";

export default function EntityOverview({
  entity,
  title,
  nestedEntity = false,
  parent = {},
}) {
  const { globalFilters } = useContext(GlobalContext);
  const location = useLocation();
  const pageTitle = formatPageTitle(title);

  // Object and CableType lists should not be affected by no global filtering
  const isExcludedFromGlobalFiltering = useMemo(
    () =>
      [ROUTES.object.list, ROUTES.cableType.list].some((route) =>
        location.pathname.toLowerCase().includes(route)
      ),
    [location.pathname]
  );

  if (
    !globalFilters?.value &&
    !nestedEntity &&
    !isExcludedFromGlobalFiltering
  ) {
    return (
      <Alert severity='warning'>
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
        Please choose an object in the upper right list to display the records.
      </Alert>
    );
  }

  return (
    <LayoutWrapper
      condition={!nestedEntity}
      wrapper={(children) => <Box>{children}</Box>}
    >
      {!nestedEntity && (
        <Helmet>
          <title>{pageTitle}</title>
        </Helmet>
      )}
      {nestedEntity && (
        <Divider
          sx={{
            margin: "1rem 0",
          }}
        />
      )}
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Restricted to={UserRights.READ} entity={entity}>
          {entity === FILTER_TYPES.object && <ObjectsContainer />}
          {entity === FILTER_TYPES.project && (
            <ProjectsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.system_group && (
            <SystemGroupsContainer
              nestedEntity={nestedEntity}
              parent={parent}
            />
          )}
          {entity === FILTER_TYPES.system && (
            <SystemsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.location && (
            <LocationsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.equipment && (
            <EquipmentsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.section && (
            <SectionsContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.cable && (
            <CablesContainer nestedEntity={nestedEntity} parent={parent} />
          )}
          {entity === FILTER_TYPES.cable_type && <CableTypesContainer />}
          {entity === FILTER_TYPES.cable_bundle && (
            <CableBundlesContainer
              nestedEntity={nestedEntity}
              parent={parent}
            />
          )}
          {entity === FILTER_TYPES.route_point && <RoutePointsContainer />}
          {entity === FILTER_TYPES.drum && <DrumsContainer />}
          {entity === FILTER_TYPES.order && <OrdersContainer />}
        </Restricted>
      </Box>
    </LayoutWrapper>
  );
}
